import { useTranslation } from 'react-i18next';
import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import ReactTooltip from 'react-tooltip';

import {
    objectsState,
    positionsFetchingState,
    positionsState,
    usersState,
} from '../../../../../redux/slices/newsAndPollsSlice';
import { addUniqueElements } from '../../../../../api/functions';
import { SvgComponent } from '../../../../common/svgComponent/SvgComponent';
import { Checkbox } from '../../../../ui/checkbox/Checkbox';
import { Loader } from '../../../../common/Loader';

export const PositionsSection = ({
    selectedPositions,
    setSelectedObjects,
    setSelectedPositions,
    setSelectedUsers,
    isDisabled,
}) => {
    const { t } = useTranslation();

    const loading = useSelector(positionsFetchingState);
    const objects = useSelector(objectsState);
    const positions = useSelector(positionsState);
    const users = useSelector(usersState);

    const [searchLine, setSearchLine] = useState('');
    const [onlySelected, setOnlySelected] = useState(false);

    const positionsMemo = useMemo(
        () => positions.filter(item => item.name.toLowerCase().indexOf(searchLine.toLowerCase()) >= 0),
        [positions, searchLine]
    );

    useEffect(() => {
        ReactTooltip.rebuild();
    }, [positionsMemo]);

    const selectAllItemsHandler = e => {
        if (e.target.checked) {
            setSelectedObjects(objects);
            setSelectedPositions(positionsMemo);
            setSelectedUsers(users);
        } else {
            setSelectedObjects([]);
            setSelectedPositions([]);
            setSelectedUsers([]);
        }
    };

    const selectItemHandler = e => {
        const position = positionsMemo.find(el => el.id === e.target.value);

        if (e.target.checked) {
            setSelectedPositions(prevState => [...prevState, position]);

            setSelectedObjects(prevState => [
                ...addUniqueElements(
                    prevState,
                    objects.filter(object => object.positions.includes(position.id)),
                    'id'
                ),
            ]);

            setSelectedUsers(prevState => [
                ...addUniqueElements(
                    prevState,
                    users.filter(user => user.objects.find(obj => obj.position === position.id)),
                    'id'
                ),
            ]);
        } else {
            setSelectedPositions(prevState => prevState.filter(el => el.id !== position.id));

            setSelectedObjects(prevState =>
                prevState.filter(object =>
                    [
                        ...new Set(selectedPositions.filter(el => el.id !== position.id).flatMap(el => el.objects)),
                    ].includes(object.id)
                )
            );

            setSelectedUsers(prevState =>
                prevState.filter(user =>
                    [
                        ...new Set(selectedPositions.filter(el => el.id !== position.id).flatMap(el => el.users)),
                    ].includes(user.id)
                )
            );
        }
    };

    return (
        <div className="w-[calc(100%/3-10px)]">
            <div className="mb-3 text-xs text-neutral-900 font-medium">{t('NEWS_AND_POLLS_SELECT_POSITION')}</div>

            <div className="mb-3 text-xs">
                <Checkbox
                    title={`${t('NEWS_AND_POLLS_SHOW_ONLY_SELECTED')} (${selectedPositions.length})`}
                    onClick={e => {
                        setOnlySelected(e.target.checked);
                    }}
                    checked={onlySelected}
                />
            </div>

            <div className="flex-1 h-[420px] border border-gray-200 rounded overflow-y-auto overflow-x-hidden">
                <div className="sticky top-0 p-1 bg-white">
                    <div className="w-full h-10 relative">
                        <div className="text-gray absolute left-2.5 top-3 w-4 h-4">
                            <SvgComponent name="search" />
                        </div>

                        <input
                            type="text"
                            placeholder={t('NEWS_AND_POLLS_SEARCH_BY_POSITIONS')}
                            className="box-border w-full h-full text-sm rounded border-gray-200 pl-8"
                            value={searchLine}
                            onChange={e => setSearchLine(e.target.value)}
                            disabled={isDisabled}
                        />
                    </div>
                </div>

                <div className="flex flex-col gap-2 px-3 py-2 text-sm">
                    {loading ? (
                        <div className="flex justify-center">
                            <Loader width={24} />
                        </div>
                    ) : (
                        !!positionsMemo.length && (
                            <>
                                {!searchLine.length && !onlySelected && (
                                    <div className="flex items-center py-px">
                                        <Checkbox
                                            name="position"
                                            value="all"
                                            title={t('NEWS_AND_POLLS_ALL_POSITIONS')}
                                            onClick={selectAllItemsHandler}
                                            checked={
                                                selectedPositions.length === positionsMemo.length &&
                                                positionsMemo.length > 0
                                            }
                                            disabled={isDisabled}
                                        />
                                    </div>
                                )}

                                {positionsMemo.map(item => {
                                    const isChecked = selectedPositions.findIndex(el => el.id === item.id) > -1;

                                    return (
                                        <div
                                            key={item.id}
                                            className={`inline-flex items-center py-px ${onlySelected && !isChecked && 'hidden'}`}
                                            data-tip={item.name}
                                        >
                                            <Checkbox
                                                name="position"
                                                value={item.id}
                                                title={item.name}
                                                checked={isChecked}
                                                onClick={selectItemHandler}
                                                disabled={isDisabled}
                                            />
                                        </div>
                                    );
                                })}
                            </>
                        )
                    )}
                </div>
            </div>
        </div>
    );
};
